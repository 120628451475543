<template>
    <div>
        <Edit
            :item="currentItem"
            :close="closeEdit"
            :onSave="saveRelatorio"
            :perfis="perfis"
            :tipos="tipos"
        />
        <CTabs variant="tabs" class="nav-tabs-boxed">
            <CTab title="Relatórios">
                <div class="filters row" style="margin-top:0">
                    <div class="col-1-1">
                        <span class="filters__search">
                            <input
                                    type="text"
                                    v-model="termo"
                                    placeholder="Buscar por..."
                                    v-on:input="debounceLoadRelatorios()">
                            <img
                                    style="position: relative; right: -10px;"
                                    src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDU2Ljk2NiA1Ni45NjYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDU2Ljk2NiA1Ni45NjY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4Ij4KPHBhdGggZD0iTTU1LjE0Niw1MS44ODdMNDEuNTg4LDM3Ljc4NmMzLjQ4Ni00LjE0NCw1LjM5Ni05LjM1OCw1LjM5Ni0xNC43ODZjMC0xMi42ODItMTAuMzE4LTIzLTIzLTIzcy0yMywxMC4zMTgtMjMsMjMgIHMxMC4zMTgsMjMsMjMsMjNjNC43NjEsMCw5LjI5OC0xLjQzNiwxMy4xNzctNC4xNjJsMTMuNjYxLDE0LjIwOGMwLjU3MSwwLjU5MywxLjMzOSwwLjkyLDIuMTYyLDAuOTIgIGMwLjc3OSwwLDEuNTE4LTAuMjk3LDIuMDc5LTAuODM3QzU2LjI1NSw1NC45ODIsNTYuMjkzLDUzLjA4LDU1LjE0Niw1MS44ODd6IE0yMy45ODQsNmM5LjM3NCwwLDE3LDcuNjI2LDE3LDE3cy03LjYyNiwxNy0xNywxNyAgcy0xNy03LjYyNi0xNy0xN1MxNC42MSw2LDIzLjk4NCw2eiIgZmlsbD0iIzAwNDE2ZiIvPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K"
                            >
                        </span>

                        <div class="pull-right">
                            <button v-on:click="openCreate" class="button button-success button-block">
                                <fa-icon icon="plus" />
                                Novo Relatório
                            </button>
                        </div>
                    </div>
                </div>
                <div class="table-responsive">
                    <datatable :columns="table.columns" :data="items" class="table">
                        <template slot-scope="{ row }">
                            <tr>
                                <td class="highlight">{{ row.id }}</td>
                                <td>{{ row.titulo }}</td>
                                <td>{{ row.descricao }}</td>
                                <td>{{ row.criacao | datetime }}</td>
                                <td class="actions">
                                    <button type="button" class="button button-primary" v-on:click="openEdit(row)">
                                        <fa-icon icon="pencil-alt" />
                                    </button>
                                </td>
                            </tr>
                        </template>
                    </datatable>
                </div>
                <div style="display: flex; justify-content: center;">
                    <pagination
                            :data="table.paginator"
                            :limit="5"
                            @pagination-change-page="loadRelatorios"
                    ></pagination>
                </div>
            </CTab>
        </CTabs>

    </div>
</template>

<script>

import { get, post, put, del } from "@/helpers/apiRequest";
import _debounce from "lodash/debounce";
import Edit from "./Edit";
import {isEmpty} from "lodash";

const relatorioItem = () => {
  return {
    titulo: "",
    descricao: "",
    query:
      "SELECT 1 AS NOMECOLUNA1, \n" +
      "       2 AS NOMECOLUNA2\n" +
      "  FROM DUAL",
    banco: "local",
    ativo: true,
    disposicao: "P",
    modo_exibicao: "TABELA",
    perfis: [],
    parametros: [],
    resumos: [],
    formatosColunas: [],
    tipo: null,
    envia_automatico: false,
    envioAutomatico: {},
    permite_download: 1
  };
};

export default {
  name: "relatorios",
  components: {
    Edit,
  },
  data() {
    return {
      currentItem: {},
      loading: false,
      error: false,
      items: [],
      perfis: [],
      tipos: [],
      termo: "",
      currentPage: 1,
      table: {
        filter: "",
        columns: [
          { label: "ID", field: "id", align: "center", sortable: false },
          { label: "Título", field: "titulo", sortable: false },
          { label: "Descrição", field: "descricao", sortable: false },
          { label: "Cadastro", field: "descricao", sortable: false },
          { label: "", field: "", sortable: false },
        ],
        paginator: {},
      },
    };
  },
  methods: {
    loadPerfis() {
      get("/admin/perfis")
        .then((json) => {
          this.perfis = json;
        })
        .catch((err) => {});
    },
    loadTiposRelatorio() {
      get("/admin/relatorios/tipos-relatorio")
        .then((json) => {
          this.tipos = json;
        })
        .catch((err) => {});
    },

    debounceLoadRelatorios: _debounce(function() {
      this.loadRelatorios(1);
    }, 300),

    loadRelatorios(page = 1) {
      this.currentPage = page;

      get(`/admin/relatorios?page=${page}&q=${this.termo}`)
        .then((json) => {
          this.items = json.itens;
          this.table.paginator = json.paginator;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    refreshRelatorios() {
      this.loadRelatorios(this.currentPage);
    },
    openCreate() {
      this.currentItem = relatorioItem();
      this.$modal.show("relatorioEdit");
    },
    openEdit(item) {
      get(`/admin/relatorios/${item.id}`).then((relatorio) => {
        let { envioAutomatico } = relatorio;
        relatorio.envioAutomatico = !isEmpty(envioAutomatico) ? envioAutomatico : {}

        this.currentItem = relatorio;
        this.$modal.show("relatorioEdit");
      });
    },
    closeEdit() {
      this.$modal.hide("relatorioEdit");
      this.refreshRelatorios();
    },
    saveRelatorio() {
      let request;

      if (!!this.currentItem.id) {
        request = put(
          `/admin/relatorios/${this.currentItem.id}`,
          this.currentItem
        );
      } else {
        request = post(`/admin/relatorios`, this.currentItem);
      }

      request.then((json) => {
        this.closeEdit();          
        this.$swal({
          title: 'Salvo com sucesso',
          text: json.message,
          showCancelButton: false,
          cancelButtonText: 'Fechar',
          showConfirmButton: true,
          icon: 'success',
        });          
      })
      .catch((error) => {
        this.$swal({
          title: 'Falha ao Salvar',
          text: error.message,
          showCancelButton: true,
          cancelButtonText: 'Fechar',
          showConfirmButton: false,
          icon: 'error',
        });
      });
    },
  },
  beforeMount() {
    this.loadRelatorios();
    this.loadPerfis();
    this.loadTiposRelatorio();
  },
};
</script>
